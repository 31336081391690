import React from 'react'

export const columns = [
    {
     selector: "name",
     name: "Name",
     sortable: true,
     center:true,
    },
    {
     selector: "user_id",
     name: "User",
     sortable: true,
     center:true,
    },
    {
     selector: "fqdn",
     name: "Fqdn",
     sortable: true,
     center:true,
    },
    {
     selector: "remote_path",
     name: "Path",
     sortable: true,
     center:true,
    },
    {
     selector: "created_at",
     name: "Creata il",
     sortable: true,
     center:true,
    },
    {
        selector: 'updated_at',
        name:"Aggiornata il",
        sortable: true,
        center:true,
    },
    {
        selector: 'action',
        name:"edit",
        sortable: true,
        center:true,
    },

   ];

