import {GET_CDN_LIST, SELECT_CDN} from '../../redux/actionTypes'
import React from "react";
const initial_state = {

}

export default (state = initial_state, action) => {

    switch (action.type) {
        case GET_CDN_LIST:
            const newData = action.cdn.data.map(data => ({
                ...data,
                action:
                    <div>
                        <span><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e', cursor: "pointer" }}></i></span>
                        <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)', cursor: "pointer" }} onClick={ () => window.location.href = `${process.env.PUBLIC_URL}/base/tabs/tab-bootstrap#` + data.id}></i></span>
                    </div>
            }))

            return {...state,cdn: newData, loading: true,};
        case SELECT_CDN:
            debugger;
            console.log('porca madonna negra')
        default: return { ...state };
    }

}
