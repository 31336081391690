import {call, put, takeLatest} from "redux-saga/effects";
import {GET_CDN_LIST, WATCH_CDN_LIST} from '../../redux/actionTypes';
import {fetchCdnApi} from "../../api";

function* fetchCdnList() {
    const Data = yield call(fetchCdnApi);
    yield put({type : GET_CDN_LIST , cdn: Data});
}

export function* watcherCdnList() {
    yield takeLatest(WATCH_CDN_LIST,fetchCdnList)
}
