import React,{useState,Fragment} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import {Container,Row,Col,Card,CardHeader,CardBody,Nav,NavItem,NavLink,TabContent,TabPane} from 'reactstrap'
import {CDNS, Config, Pods, Flush} from '../../../constant'
import TodoFirebase from "../../applications/todo-firebase-app/todo-firebase";

const TabBootstrap = (history) => {
  const [VerticleTab, setVerticleTab] = useState('1');

    return (
      <Fragment>
        <Breadcrumb parent="Base / Tabs" title="CND Detail"/>
        <Container fluid={true}>
            <Row>

              <Col sm="12" xl="12 xl-100">
                <Card>
                  <CardHeader>
                    <h5>{CDNS}</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="3" xs="12">
                        <Nav className="nav flex-column nav-pills">
                          <NavItem>
                            <NavLink className={VerticleTab === '1' ? 'active' : ''} onClick={() => setVerticleTab('1')}>{Config}</NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink className={VerticleTab === '2' ? 'active' : ''} onClick={() => setVerticleTab('2')}>{Flush}</NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink className={VerticleTab === '3' ? 'active' : ''} onClick={() => setVerticleTab('3')}>{Pods}</NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                      <Col sm="9" xs="12">
                         <TabContent activeTab={VerticleTab}>
                          <TabPane  className="fade show" tabId="1">
                          <p>{"Set your CDN configuration"}</p>
                          </TabPane>
                          <TabPane tabId="2">
                          <TodoFirebase/>
                          </TabPane>
                          <TabPane tabId="3">
                          <p>{"Work in progress"}</p>
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          </Fragment>
    );
}

export default TabBootstrap;
