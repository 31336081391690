import React, {Fragment, useEffect} from 'react';
import DataTable from 'react-data-table-component'
import {columns} from '../../../data/server-table';
import Breadcrumb from '../../common/breadcrumb';
import { useDispatch, useSelector } from "react-redux";

const ServerComponent = () => {
    const dispatch = useDispatch();
    const cdnList = useSelector(state => state.Cdn.cdn);

    useEffect(() => {
        dispatch({type: "WATCH_CDN_LIST"})
    }, []);

    return (
        <Fragment>
            <Breadcrumb parent="Dashboard" title="CDN" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 xl-100">
                        <div className="card">
                            <div className="card-header server-header">
                                <h5 className="d-inline-block">CDN List</h5><span className="badge badge-primary m-l-10 d-inline-block mt-0">{cdnList ? cdnList.length + " CND" : ''}</span>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable
                                         columns={columns}
                                         data={cdnList}
                                         striped={true}
                                         center={true}
                                         persistTableHead
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ServerComponent; 
