import {
    Home,
    Server,
    HelpCircle
} from 'react-feather';

export const MENUITEMS = [
    {
        title: 'CDN', icon: Home, type: 'sub', badgeType: 'primary', active: false, children: [
            { path: '/dashboard/server', title: 'CND List', type: 'link' },
        ]
    },
    {
        title: 'FAQ', icon: HelpCircle, type: 'link', path: '/faq/faqComponent', active: false
    },
]
