import React, { Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker, Polygon, Polyline } from "react-google-maps";
import { CurrentLocation,BasicDemo,MarkerMap,Polygons,Polylines } from "../../constant";
const GoogleMaps = (props) => {

    const marks = [{"lat":45.46856131293906,"lng":8.891354940420593},{"lat":45.459527791698825,"lng":8.889941250468434},{"lat":45.451378726674875,"lng":8.885838593323147},{"lat":45.44491192374957,"lng":8.879448646475733},{"lat":45.44076039833318,"lng":8.871397002238607},{"lat":45.43933041166641,"lng":8.862471892225823},{"lat":45.44076174878643,"lng":8.853546998988083},{"lat":45.444914108828954,"lng":8.84549592227543},{"lat":45.451380911754434,"lng":8.83910667692692},{"lat":45.45952914215235,"lng":8.835004587306234},{"lat":45.46856131293906,"lng":8.833591114129195},{"lat":45.47759348372577,"lng":8.835004587306234},{"lat":45.48574171412369,"lng":8.83910667692692},{"lat":45.49220851704918,"lng":8.84549592227543},{"lat":45.4963608770917,"lng":8.853546998988083},{"lat":45.49779221421172,"lng":8.862471892225823},{"lat":45.496362227544935,"lng":8.871397002238607},{"lat":45.49221070212855,"lng":8.879448646475733},{"lat":45.48574389920326,"lng":8.885838593323147},{"lat":45.47759483417929,"lng":8.889941250468434},{"lat":45.46856131293906,"lng":8.891354940420593}]

    const [location, setlocation] = useState({
        address: false,
        mapPosition: {
            lat: 18.5204, lng: 73.8567
        },
        markerPosition: {
            lat: 18.5204, lng: 73.8567
        }
    })

    const polygontriangleCoords = [
        { lat: 25.774, lng: -80.190 },
        { lat: 18.466, lng: -66.118 },
        { lat: 32.321, lng: -64.757 },
        { lat: 25.774, lng: -80.190 }
    ];

    const polylinetriangleCoords = [
        { lat: 37.772, lng: -122.214 },
        { lat: 21.291, lng: -157.821 },
        { lat: -18.142, lng: 178.431 },
        { lat: -27.467, lng: 153.027 }
    ];


    const showinfowindow = () => {
        setlocation({ ...location, address: true })
    }
    const BasicMap = withScriptjs(
        withGoogleMap(
            props => (
                <GoogleMap google={props.google}
                    defaultZoom={15}
                    defaultCenter={{ lat: location.mapPosition.lat, lng: location.mapPosition.lng }}
                >
                </GoogleMap>
            )
        )
    );

    const MarkupMap = withScriptjs(
        withGoogleMap(
            props => (
                <GoogleMap google={props.google}
                    defaultZoom={15}
                    defaultCenter={{ lat: location.mapPosition.lat, lng: location.mapPosition.lng }}
                >
                    {marks.map((item, key) => (
                        <Marker google={props.google}
                                name={'Dolores park'}
                                draggable={true}
                                onClick={showinfowindow}
                                position={{ lat: item.lat, lng: item.lng }}
                        />
                    ))
                    }
                    <Marker google={props.google}
                        name={'Dolores park'}
                        draggable={true}
                        onClick={showinfowindow}
                        position={{ lat: location.markerPosition.lat, lng: location.markerPosition.lng }}
                    />
                    <Marker />
                    {location.address ?
                        <InfoWindow
                            position={{ lat: (location.markerPosition.lat + 0.0018), lng: location.markerPosition.lng }}
                        >
                            <div>
                                <span style={{ padding: 0, margin: 0 }}>{CurrentLocation}</span>
                            </div>
                        </InfoWindow>
                        : ""}
                </GoogleMap>
            )
        )
    );

    const PolygonMap = withScriptjs(
        withGoogleMap(
            props => (
                <GoogleMap google={props.google}
                    defaultZoom={5}
                    defaultCenter={{ lat: 24.886, lng: -70.268 }}
                >
                    <Polygon
                        paths={polygontriangleCoords}
                        strokeColor="#FF0000"
                        strokeOpacity={0.8}
                        strokeWeight={2}
                        fillColor="#FF0000"
                        fillOpacity={0.35}

                    />
                </GoogleMap>
            )
        )
    );

    const PolylineMap = withScriptjs(
        withGoogleMap(
            props => (
                <GoogleMap google={props.google}
                    defaultZoom={3}
                    defaultCenter={{ lat: 0, lng: -180 }}
                >
                    <Polyline
                        paths={polylinetriangleCoords}
                        geodesic={true}
                        strokeColor='#FF0000'
                        strokeOpacity={1.0}
                        strokeWeight={2}
                    />
                </GoogleMap>
            )
        )
    );

    return (
        <Fragment>
            <Breadcrumb parent="Map" title="Google Maps" />
            <Container fluid={true}>
                <Row>
                    <Col xl="6" md="12">
                        <Card>
                            <CardHeader>
                                <h5>{BasicDemo}</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="map-js-height">
                                    <div id="gmap-simple" className="map-block">
                                        <BasicMap
                                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCdXpLSJ3Ibdu-Phs9QOvpqb9d1DtPf7wQ&libraries=places"
                                            loadingElement={
                                                <div style={{ height: `100%` }} />
                                            }
                                            containerElement={
                                                <div style={{ height: '300px' }} />
                                            }
                                            mapElement={
                                                <div style={{ height: `100%` }} />
                                            }
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xl="6" md="12">
                        <Card>
                            <CardHeader>
                                <h5>{MarkerMap}</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="map-js-height">
                                    <div id="gmap-simple" className="map-block">
                                        <MarkupMap
                                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCdXpLSJ3Ibdu-Phs9QOvpqb9d1DtPf7wQ&libraries=places"
                                            loadingElement={
                                                <div style={{ height: `100%` }} />
                                            }
                                            containerElement={
                                                <div style={{ height: '300px' }} />
                                            }
                                            mapElement={
                                                <div style={{ height: `100%` }} />
                                            }
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xl="6" md="12">
                        <Card>
                            <CardHeader>
                                <h5>{Polygons}</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="map-js-height">
                                    <div id="gmap-simple" className="map-block">
                                        <PolygonMap
                                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCdXpLSJ3Ibdu-Phs9QOvpqb9d1DtPf7wQ&libraries=places"
                                            loadingElement={
                                                <div style={{ height: `100%` }} />
                                            }
                                            containerElement={
                                                <div style={{ height: '300px' }} />
                                            }
                                            mapElement={
                                                <div style={{ height: `100%` }} />
                                            }
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl="6" md="12">
                        <Card>
                            <CardHeader>
                                <h5>{Polylines}</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="map-js-height">
                                    <div id="gmap-simple" className="map-block">
                                        <PolylineMap
                                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCdXpLSJ3Ibdu-Phs9QOvpqb9d1DtPf7wQ&libraries=places"
                                            loadingElement={
                                                <div style={{ height: `100%` }} />
                                            }
                                            containerElement={
                                                <div style={{ height: '300px' }} />
                                            }
                                            mapElement={
                                                <div style={{ height: `100%` }} />
                                            }
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default GoogleMaps
